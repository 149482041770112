<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Бренд</th>
                  <th>Категория</th>
                  <th>Валюта</th>
                  <th>Цена (за кг)</th>
                  <th>Дата</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="value in values" :key="value.id">
                  <td class="font-weight-bold">#{{ value.id }}</td>
                  <td>{{ value.brand ? value.brand.name : null }}</td>
                  <td>{{ value.category ? value.category.name : null }}</td>
                  <td>{{ value.currency ? value.currency.name : null }}</td>
                  <td>{{ value.cost }}</td>
                  <td>{{ value.created_at | formattedDate('dd.MM.yyyy HH:mm:ss') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PriceList',
  data() {
    return {
      values: [],
      searchQuery: null,
      inStock: null,
    };
  },
  async mounted() {
    await this.getValues();
  },
  methods: {
    async getValues() {
      this.values = (await this.$api.priceList.values()).data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}

.fixed-price {
  background-color: #f5f5f5;
}

.discount {
  text-transform: uppercase;
  &.warning {
    color: $warning;
    text-transform: uppercase;
  }
  &.danger {
    color: $danger;
  }
}
</style>
